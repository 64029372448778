import React, { useEffect, useRef, useState } from 'react';
import firebase from "firebase/app";
import "firebase/storage";
import styles from "./Photos.module.scss";
import { CircularProgress } from "@material-ui/core";
import Snackbar, { SnackbarRef } from "../widgets/Snackbar";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {v4 as uuid} from "uuid";

export default function Photos() {
    const snackbarRef = useRef<SnackbarRef>(null);
    const [message, setMessage] = useState<string>("");
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
    // const supported = 'mediaDevices' in navigator;

    // console.log('supported', supported);
    useEffect(() => {
        (async () => {
            await fetchImages();
        })()
    }, [])

    const fetchImages = async () => {
        try {
            const storage = firebase.storage();
            const newImages: any[] = [];
            await storage.ref("event_photos/thumbs").listAll()
                .then((res) => {
                    res.items.forEach(async (ref) => {
                        if (ref.fullPath.includes("600x600")) {
                            await ref.getDownloadURL().then((url) => {
                                newImages.push({ original: url, originalClass: styles.gallery_image });
                            })
                        }
                    })
                })
            setImages(newImages);
        } catch (e) {
            console.log('error fetching refs', e);
        } finally {
            setLoading(false);
        }
    }

    const handlePhoto = async (e: any) => {

        let imageFiles = [];
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            if (files[i].type.match(/^image\//)) {
                imageFiles.push(files[i]);
            }
        }

        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                console.log('file', file);
                handleUpload(file);
            })
        }
    }

    const handleUpload = (file: any) => {
        const inputEl: any = document.getElementById("image-upload");
        setUploading(true);

        const uploadTask = firebase.storage().ref(`/event_photos/${uuid()}_${file.name}`).put(file, { contentType: file.type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes
            setProgress(progress);
        }, (e) => {
            console.log('upload error', e.message);
            setMessage("Upload error");
            snackbarRef.current?.show();
            setUploading(false);
            if (inputEl) {
                inputEl.value = "";
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                setUploading(false);
                setMessage("Upload success");
                snackbarRef.current?.show();
                inputEl.value = "";
            })
        })
    }

    const handleSnackbarClose = async () => {
        setMessage("");
        // await fetchImages();
    }

    // const renderImage = (image: ReactImageGalleryItem) => {
    //     console.log('image', image);
    //     return (
    //         <img src={image.original} className={styles.gallery_image} />
    //     )
    // }

    // navigator.mediaDevices?.enumerateDevices()
    //     .then((devices) => console.log('devices', devices));
    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>Photos</h1>
            <p>Upload your photos of the wedding here by pressing the button below. Browse the gallery below to see
                photos other guests in Auckland & Brisbane have taken or look for your photos on the TV at the
                wedding!</p>
            <div className={styles.upload_container}>
                <label htmlFor={"image-upload"} className={styles.image_label}>
                    <AddAPhotoIcon/>
                    Press to upload photos</label>
                <input
                    className={styles.image_input}
                    disabled={uploading}
                    id={"image-upload"}
                    accept={"image/*"}
                    type={"file"}
                    multiple={true}
                    onChange={handlePhoto}/>
                {uploading && <CircularProgress variant={"determinate"} value={progress * 100}/>}
            </div>
            {loading ? <div className={styles.loading_wrapper}><CircularProgress size={50}/></div> :
                <ReactImageGallery items={images} showThumbnails={false}/>}
            <Snackbar ref={snackbarRef} message={message} onClose={handleSnackbarClose}/>
        </div>
    );
}
