import React from 'react';
import styles from "./Accommodation.module.scss";

export default function Accommodation() {
    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>Where to stay?</h1>
            <p>We're very excited to share our special day with you and want to make sure you enjoy your stay on Waiheke
                Island. On the day we have booked buses to collect our guests from Matiatia Ferry Terminal (the main
                ferry drop off on the island), the buses will be making one stop at Waiheke Island Resort in Palm Beach
                to collect more guests on their way to the venue at Man 'O War Vineyard. We will be hosting an after
                wedding brunch on Sunday 4th April at James' parents place which we would love to see you at. Staying on
                Waiheke Island would be the easiest way to attend this.</p>

            <div className={styles.hero}/>

            <h3>Staying on Waiheke Island</h3>
            <p>If you would like to stay on the island we recommend booking at <a
                href={"https://www.waihekeresort.co.nz/"} target={"_blank"} rel={"noopener noreferrer"}>Waiheke Island Resort</a> or otherwise
                booking nearby in <a
                    href={"https://www.airbnb.co.nz/s/Palm-Beach--Auckland--New-Zealand/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=Palm%20Beach%2C%20Auckland%2C%20New%20Zealand&place_id=ChIJtzx-YT_Jcm0REDOjQ2HvAAU&source=structured_search_input_header&search_type=autocomplete_click"}
                    target={"_blank"}
                rel={"noopener noreferrer"}>Palm Beach</a> to make it easy to reach the bus collection point.
            </p>

            <h3>Staying in Auckland City</h3>
            <p>There are many good accommodation options in downtown Auckland as well. We would recommend that you try
                to book somewhere in walking distance of the downtown ferry terminal which is where you will depart from
                to reach Waiheke on our wedding day.</p>
            <p>Some good options a short distance from the ferry terminal are:</p>
            <ul>
                <li><a href={"https://www.adinahotels.com/en/apartments/auckland-britomart/"} target={"_blank"} rel={"noopener noreferrer"}>Adina Apartments</a></li>
                <li><a href={"https://www.hilton.com/en/hotels/aklhihi-hilton-auckland/"} target={"_blank"} rel={"noopener noreferrer"}>The Hilton</a></li>
                <li><a href={"https://www.hotelgrandwindsor.com/"} target={"_blank"} rel={"noopener noreferrer"}>Hotel Grand Windsor</a></li>
                <li><a href={"https://www.thesebelauckland.co.nz/"} target={"_blank"} rel={"noopener noreferrer"}>The Sebel</a></li>
            </ul>
        </div>
    );
}

