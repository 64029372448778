import React, {useRef, useState} from 'react';
import styles from "./RSVP.module.scss";
import {Form, Formik} from "formik";
import {TextField} from "@material-ui/core";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import Snackbar, {SnackbarRef} from "../widgets/Snackbar";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import routes from "../../constants/routes";
import {useHistory} from 'react-router-dom';
import FormHelperText from "@material-ui/core/FormHelperText";

export default function RSVP() {
    const snackbarRef = useRef<SnackbarRef>(null);
    const [message, setMessage] = useState<string>("");
    const [rsvpSuccess, setRsvpSuccess] = useState<boolean>(false);
    const db = firebase.firestore();
    const functions = firebase.functions();
    const history = useHistory();

    const VENUES = {
        WAIHEKE: "waiheke",
        BRISBANE: "brisbane",
        SYDNEY: "sydney",
        NONE: "none"
    }

    const initialValues = {
        name: "",
        email: "",
        attending: VENUES.WAIHEKE,
        busOut: "",
        busHome: "",
        // children: "",
        allergies: "",
        songs: "",
        brunch: "",
    }

    const validationSchema = yup.object({
        name: yup.string().required("Required"),
        email: yup.string().email("Email address invalid").required("Required"),
        attending: yup.string(),
        busHome: yup.string().ensure().when("attending", {
            is: VENUES.WAIHEKE,
            then: yup.string().required("Required")
        }),
        allergies: yup.string().when("attending", {
            is: (val: any) => val !== "none",
            then: yup.string().required("Required")
        }),
        songs: yup.string(),
        brunch: yup.string().ensure().when("attending", {
            is: VENUES.WAIHEKE,
            then: yup.string().required("Required")
        }),
    })

    const onSubmit = async (values: any) => {
        try {
            const user = await firebase.auth().createUserWithEmailAndPassword(values.email, values.email);
            if (user) {
                await saveRSVP(values);
            }
        } catch (e) {
            let rsvpResave = false;
            await db.collection("rsvp").where("email", "==", values.email)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot?.docs?.length) {
                        // no rsvp exists, save submitted rsvp
                        rsvpResave = true;
                        return saveRSVP(values);
                    }
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
            if (!rsvpResave) {
                await saveRSVP(values, e);
                setMessage(e.message || "There was an error saving your RSVP");
                snackbarRef?.current?.show();
            }
        }
    }

    const saveRSVP = async (values: any, error?: any) => {
        const collection = error ? "rsvpError" : "rsvp";
        try {
            await db.collection(collection).add({
                name: values.name,
                email: values.email,
                attending: values.attending,
                busOut: values.busOut,
                busHome: values.busHome,
                allergies: values.allergies,
                songs: values.songs,
                brunch: values.brunch,
                error: error?.message || "",
            })
            if (!error) {
                setRsvpSuccess(true);
            }
            setMessage("Thanks for your RSVP");
            let header = `${values.name} we can't wait to see you at our wedding`
            if (values.attending === VENUES.NONE) {
                header = `${values.name} we'll miss you at our wedding`
            }
            let body = "Thanks for sending your RSVP. We're sorry to miss you at our wedding but look forward to catching up soon after!";
            if (values.attending === VENUES.WAIHEKE) {
                body = `Thanks for sending your RSVP, we're counting down the days until you can join us on Waiheke.`
            } else if (values.attending === VENUES.BRISBANE) {
                body = `Thanks for sending your RSVP, we're so glad we can share the day with you from Brisbane.`
            } else if (values.attending === VENUES.SYDNEY) {
                body = `Thanks for sending your RSVP, we're so glad we can share the day with you from Sydney.`
            }
            await sendRsvpConfirmation(
                values.email,
                "Catherine & James RSVP",
                "",
                header,
                body)
            snackbarRef?.current?.show();
        } catch (e) {
            setMessage("We couldn't save your RSVP, please email love@catherine-james.wedding");
            snackbarRef?.current?.show();
        }
    }

    const sendRsvpConfirmation = (email: string, subject: string, preHeader: string, message: string, body: string) => {
        const callable = functions.httpsCallable("rsvpEmail");
        return callable({
            email,
            subject,
            preHeader,
            message,
            body,
        });
    }

    const handleSnackbarClose = () => {
        if (rsvpSuccess) {
            setMessage("");
            history.replace(routes.schedule);
        }
        return setMessage("");
    }

    const renderVenueDescription = (v: string) => {
        switch (v) {
            case VENUES.WAIHEKE: {
                return <p>The Waiheke venue is Man 'O War Vineyard at <a
                    href={"https://www.google.co.nz/maps/place/Man+O'+War+Vineyards/@-36.7862576,175.1519154,17z/data=!3m1!4b1!4m8!3m7!1s0x6d72c68c240cf94b:0xef27a5b5c320ab12!5m2!4m1!1i2!8m2!3d-36.7862619!4d175.1541041"}
                    target={"_blank"} rel={"noopener noreferrer"}>725 Man O War Bay Road, Waiheke Island</a></p>
            }
            case VENUES.BRISBANE: {
                return <p>The Brisbane venue is Indooroopilly Golf Club, on <a
                    href={"https://www.google.co.nz/maps/place/Indooroopilly+Golf+Club/@-27.5178773,152.9960744,17z/data=!3m1!4b1!4m5!3m4!1s0x6b91506ea68b8b0f:0x4bfbaa46582eac4c!8m2!3d-27.5178821!4d152.9982631"}
                    target={"_blank"} rel={"noopener noreferrer"}>Meiers Rd, Indooroopilly</a></p>
            }
            case VENUES.SYDNEY: {
                return <p>The Sydney event is being hosted by Catherine's Aunt & Uncle, Elizabeth & John, at their home
                    on <a
                        href={"https://www.google.co.nz/maps/place/51+Church+St,+Castle+Hill+NSW+2154,+Australia/@-33.7407721,151.0035661,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12a176b0220549:0x7713897776c436ff!8m2!3d-33.7407766!4d151.0057548"}
                        target={"_blank"} rel={"noopener noreferrer"}>51 Church Street, Castle Hill</a></p>
            }
            default: {
                return null;
            }
        }
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>R.S.V.P</h1>
            <div className={styles.form_wrapper}>
                <Formik initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}>
                    {({values, errors, touched, handleChange, dirty, isValid, isSubmitting}) => {
                        return (
                            <Form>
                                <div>
                                    <InputLabel id={"attending"} className={styles.select_label}>Can you attend, and at
                                        which venue?</InputLabel>
                                    <Select
                                        id={"attending"}
                                        name={"attending"}
                                        value={values.attending}
                                        onChange={handleChange}
                                        className={styles.select_input}
                                        error={touched.attending && Boolean(errors.attending)}>
                                        <MenuItem value={VENUES.WAIHEKE}>Waiheke</MenuItem>
                                        <MenuItem value={VENUES.BRISBANE}>Brisbane</MenuItem>
                                        <MenuItem value={VENUES.SYDNEY}>Sydney</MenuItem>
                                        <MenuItem value={VENUES.NONE}>Regretfully cannot attend</MenuItem>
                                    </Select>
                                    {renderVenueDescription(values.attending)}
                                    <TextField
                                        fullWidth
                                        id={"name"}
                                        name={"name"}
                                        label={"Guest names"}
                                        value={values.name}
                                        onChange={handleChange}
                                        className={styles.text_input}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}/>
                                    <TextField
                                        fullWidth
                                        name={"email"}
                                        label={"Email"}
                                        value={values.email}
                                        onChange={handleChange}
                                        className={styles.text_input}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}/>

                                    {values.attending === VENUES.WAIHEKE && (
                                        <>
                                            <p>We will be running one bus out to the venue and may have two for the
                                                return trip depending on guest's preference. Please indicate your
                                                preference below,
                                                details of the schedule can be found <a
                                                    href={"https://catherine-james.wedding/schedule"}
                                                    target={"_blank"} rel={"noopener noreferrer"}>here</a>.
                                            </p>
                                            <InputLabel id={"busHome"} className={styles.select_label}>Bus Return
                                                Preference</InputLabel>
                                            <Select
                                                id={"busHome"}
                                                name={"busHome"}
                                                value={values.busHome}
                                                onChange={handleChange}
                                                className={styles.select_input}
                                                error={touched.busHome && Boolean(errors.busHome)}>
                                                <MenuItem value={"9:30pm"}>9:30 pm</MenuItem>
                                                <MenuItem value={"11pm"}>11:00 pm</MenuItem>
                                                <MenuItem value={"neither"}>Own transport</MenuItem>
                                            </Select>
                                            <FormHelperText className={styles.helperText}
                                                            error={touched.busHome && Boolean(errors.busHome)}>{touched.busHome && errors.busHome}</FormHelperText>
                                        </>
                                    )}
                                    {values.attending !== VENUES.NONE && (
                                        <TextField
                                            fullWidth
                                            multiline
                                            name={"allergies"}
                                            label={"Any dietary allergies or needs? Please list:"}
                                            value={values.allergies}
                                            onChange={handleChange}
                                            className={styles.text_input_long}
                                            error={touched.allergies && Boolean(errors.allergies)}
                                            helperText={touched.allergies && errors.allergies}/>
                                    )}
                                    {values.attending === VENUES.WAIHEKE && (
                                        <>
                                            <TextField
                                                fullWidth
                                                multiline
                                                name={"songs"}
                                                label={"Any songs you'd like to hear at the wedding:"}
                                                value={values.songs}
                                                onChange={handleChange}
                                                className={styles.text_input_long}
                                                error={touched.songs && Boolean(errors.songs)}
                                                helperText={touched.songs && errors.songs}/>
                                            <p>Carmel and Richard will be hosting a brunch at their house the next
                                                day. We would love to see you there, if you can attend please indicate
                                                below, details of the brunch are found <a
                                                    href={"https://catherine-james.wedding/brunch"}
                                                    target={"_blank"} rel={"noopener noreferrer"}>here</a>.
                                            </p>
                                            <InputLabel id={"brunch"} className={styles.select_label}>See you for
                                                brunch?</InputLabel>
                                            <Select
                                                id={"brunch"}
                                                name={"brunch"}
                                                value={values.brunch}
                                                onChange={handleChange}
                                                className={styles.select_input}
                                                error={touched.brunch && Boolean(errors.brunch)}>
                                                <MenuItem value={"yes"}>Yes</MenuItem>
                                                <MenuItem value={"no"}>No</MenuItem>
                                            </Select>
                                            <FormHelperText className={styles.helperText}
                                                            error={touched.brunch && Boolean(errors.brunch)}>{touched.brunch && errors.brunch}</FormHelperText>
                                        </>
                                    )}
                                </div>
                                <Button
                                    type={"submit"}
                                    disabled={isSubmitting}
                                    className={styles.submit_button}>
                                    {isSubmitting ? <CircularProgress size={25}/> : "Submit"}
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <Snackbar ref={snackbarRef} message={message} onClose={handleSnackbarClose}/>
        </div>
    );
}

