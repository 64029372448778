import styles from "../photos/Photos.module.scss";
import ReactImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/storage";
import {CircularProgress} from "@material-ui/core";

export default function Gallery() {
    const [loading, setLoading] = useState<boolean>(true);
    const [images, setImages] = useState<{ [key: string]: ReactImageGalleryItem }>({});

    useEffect(() => {
        (async () => {
            await fetchImages();
        })()
        // fetch new images every 2 minutes
        setInterval(fetchImages, 120000);
    }, [])

    const fetchImages = async () => {
        const imagePaths = Object.keys(images);
        const newImages: { [key: string]: ReactImageGalleryItem } = {};
        Object.assign(newImages, images);

        try {
            const storage = firebase.storage();
            await storage.ref("event_photos").listAll()
                .then(async (res) => {

                    for (const ref of res.items) {
                        if (!imagePaths.includes(ref.fullPath)) {
                            await ref.getDownloadURL().then((url) => {
                                newImages[ref.fullPath] = {original: url, originalClass: styles.gallery_image};
                            })
                        }
                    }
                    setImages(newImages);
                })
        } catch (e) {
            console.log('error fetching refs', e);
        } finally {
            setLoading(false);
        }
    }

    const imageArray = Object.values(images);

    return (
        loading ? <div className={styles.loading_wrapper}><CircularProgress size={50}/></div> :
            <ReactImageGallery items={imageArray} showThumbnails={false}/>
    )
}