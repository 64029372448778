import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import SaveTheDate from './saveTheDate/SaveTheDate';
import Toolbar from "./widgets/toolbar/Toolbar";
import Footer from "./widgets/footer/Footer";
import routes from "../constants/routes";
import Schedule from "./schedule/Schedule";
import RSVP from "./rsvp/RSVP";
import Accommodation from "./accommodation/Accommodation";
import Registry from "./registry/Registry";
import Brunch from "./brunch/Brunch";
import styles from "./App.module.scss";
import Photos from "./photos/Photos";
import Admin from "./admin/Admin";
import Gallery from "./gallery/Gallery";

firebase.initializeApp({
    apiKey: "AIzaSyCzNKmsQYhk-6SXT0Ft0EOHhnAP8XX63_c",
    authDomain: "catherine-james.firebaseapp.com",
    databaseURL: "https://catherine-james.firebaseio.com",
    projectId: "catherine-james",
    storageBucket: "catherine-james.appspot.com",
    messagingSenderId: "688660957682",
    appId: "1:688660957682:web:291cb934d47e848f6c036b",
    measurementId: "G-67C308Y0KV"
});

firebase.analytics();

function App() {
    if (window.location.pathname === routes.baby) {
        window.location.href = "https://www.thelovelyregistry.co.nz/bubba-built";
        return null;
    }

    if (window.location.pathname === routes.saveTheDate) {
        return <SaveTheDate/>
    }

    return (
        <Router>
            <Toolbar/>
            <div className={styles.container}>
                <div className={styles.content}>
                    <Switch>
                        {/*<Route exact path={routes.home} component={Landing}/>*/}
                        <Route exact path={routes.schedule} component={Schedule}/>
                        <Route exact path={routes.rsvp} component={RSVP}/>
                        <Route exact path={routes.accom} component={Accommodation}/>
                        <Route exact path={routes.registry} component={Registry}/>
                        <Route exact path={routes.brunch} component={Brunch}/>
                        <Route exact path={routes.photos} component={Photos}/>
                        <Route exact path={routes.gallery} component={Gallery}/>
                        <Route exact path={routes.admin} component={Admin}/>
                        {/* <Redirect from={"*"} to={routes.schedule}/> */}
                    </Switch>
                </div>
            </div>
            <Footer/>
        </Router>
    );
}

export default App;
