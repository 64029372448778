import React from 'react';
import registry from "../../resources/images/registry.png";
import styles from "./Registry.module.scss";

export default function Registry() {
    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>Registry</h1>
            <p>We feel privileged to be able to share this moment in our lives with you and your presence alone, in person or virtually, will make us very happy.</p>
            <p>For those who would like to purchase a gift however we have prepared a registry you can find at <a target={"_blank"} rel={"noopener noreferrer"} href={"https://www.thelovelyregistry.co.nz/catherine-james"}>the lovely registry</a></p>
            <div className={styles.registry_wrapper} onClick={() => window.open("https://www.thelovelyregistry.co.nz/catherine-james")}>
                <img src={registry} className={styles.registry} />
            </div>
        </div>
    );
}

