import React from 'react';
import styles from "./Brunch.module.scss";

export default function Brunch() {
    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>Brunch</h1>
            <p>Richard & Carmel will be hosting a brunch at their home at 147 Te Whau Dr the day after the wedding.</p>
            <br/>
            <p>Start time: 10.00 am</p>
            <p>Finish time: 1.30 pm</p>
            <p>Parking: street parking is available</p>
            <p>Transport: There is a bus stop at the top of Te Whau drive, the Waiheke buses with “Rocky Bay” as the
                destination go to this stop. If catching the bus, shoot a text message with your estimated arrival time
                to Benoit on 022 420 2464 and he will come and collect you.</p>
            <iframe title="map" width="100%" height="450" frameBorder="0" style={{border: 0}}
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJhQrxkqLJcm0RBaSvLITV73c&key=AIzaSyCzNKmsQYhk-6SXT0Ft0EOHhnAP8XX63_c"
                    allowFullScreen></iframe>
            <div className={styles.hero}/>
        </div>
    );
}

