import React from "react";
import date from "../../resources/images/save-the-date.svg";
import styles from "./saveTheDate.module.css";

export default function SaveTheDate() {
    return (
        <div className={styles.date_wrapper} >
            <img src={date} className={styles.date_image} />
        </div>
    )
}