import React from 'react';
import styles from "./Schedule.module.scss";

export default function Schedule() {
    return (
        <div className={styles.container}>
            <h1 className={styles.cursive}>Timeline of the wedding</h1>
            <p>We have arranged for a bus to take you to the wedding venue at Man 'O War Vineyard. You may get on
                the bus either from the Matiatia ferry terminal or Waiheke Island Resort in Palm Beach, the bus will
                stop at each of these locations. We also have two buses that will make the return journey at the end of
                the evening to drop you back at those same two collection points.</p>
            <p>Since we need to stick to the ferry timetable for guests who will be coming over from Auckland for just
                the day, these times are important. It's a long way to swim if you miss your ferry! Also, taxis are
                outrageously expensive on the island as we've learnt from experience...
            </p>
            <p>The following timeline shows you
                when and where you will need to be on the day to make sure you are there for the big event! Please
                ensure you are at the terminal in plenty of time to make sure you aren't left behind if the ferry is
                full, we would recommend being in the queue half an hour before the ferry is due to depart. Waiheke is
                very popular and the ferry queues can be long.</p>
            <div className={styles.hero}/>
            <p>Schedules for the Brisbane & Sydney events to come soon.</p>
            <h3>Wedding Day - 3rd April</h3>
            <table className={styles.table}>
                <tbody>
                <tr>
                    <th>First Ferry to Waiheke</th>
                    <th>Help, I missed the ferry!</th>
                </tr>
                <tr>
                    <td>11:30am - Depart Auckland</td>
                    <td>12:00pm - The next ferry to catch if you miss the first, please contact Stephanie on <a href={"tel:0211354143"}>021 135 4143</a> asap</td>
                </tr>
                <tr>
                    <td>12:30pm - Bus departs Matiatia</td>
                </tr>
                {/*<tr>*/}
                {/*    <td>12:55pm - Bus A arrives at Waiheke Island Resort, Palm Beach</td>*/}
                {/*    <td>1:25pm - Bus B arrives at Waiheke Island Resort, Palm Beach</td>*/}
                {/*</tr>*/}
                <tr>
                    <td>12:55pm - Bus departs Waiheke Island Resort for Man 'O War</td>
                </tr>
                <tr>
                    <td>1:50pm - Bus arrives at Man 'O War</td>
                </tr>
                </tbody>
            </table>

            <table className={styles.table}>
                <tbody>
                <tr>
                    <th>Ceremony Timeline</th>
                </tr>
                <tr>
                    <td>2:00pm - Guests make their way to the chapel at Man 'O War</td>
                </tr>
                <tr>
                    <td>2:15pm - Wedding ceremony starts</td>
                </tr>
                <tr>
                    <td>3:00pm - Canapes, Drinks & Lawn games outside reception</td>
                </tr>
                <tr>
                    <td>5:15pm - Guests make their way into pavilion for start of reception</td>
                </tr>
                </tbody>
            </table>

            <table className={styles.table}>
                <tbody>
                <tr>
                    <th>First Ferry return to Auckland</th>
                    <th>Second Ferry return to Auckland</th>
                </tr>
                <tr>
                    <td>9:30pm - Bus A leaves wedding venue</td>
                    <td>11:00pm - Bus B leaves wedding venue</td>
                </tr>
                <tr>
                    <td>10:20pm - Bus A arrives back at Waiheke Island Resort</td>
                    <td>11:50pm - Bus B arrives back at Waiheke Island Resort</td>
                </tr>
                <tr>
                    <td>10:45pm - Bus A arrives at Matiatia ferry terminal</td>
                    <td>12:15am - Bus B arrives at Matiatia ferry terminal</td>
                </tr>
                <tr>
                    <td>11:00pm - Ferry A departs Matiatia to return to Auckland</td>
                    <td>12:30am - Ferry B departs Matiatia to return to Auckland</td>
                </tr>
                </tbody>
            </table>

            <h3>Recovery Brunch - 4th April</h3>
            <table className={styles.table}>
                <tbody>
                <tr>
                    <th>Brunch hosted at 147 Te Whau Drive on Waiheke Island</th>
                </tr>
                <tr>
                    <td>10:00am onwards - Breakfast, tea & coffee hosted by Richard & Carmel</td>
                </tr>
                </tbody>
            </table>

            {/*<a href={"https://calendar.google.com/calendar/u/0?cid=NGthOWhxYTNrZm5qMXFrYTBrOThhcW40Z2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"} target={"_blank"}>Add to my calendar</a>*/}
        </div>
    );
}

