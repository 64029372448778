import React, { Ref, useImperativeHandle } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

type Props = {
    message: string,
    onClose?: () => void,
}

export interface SnackbarRef {
    show(): void;
    hide(): void;
}

function Snackbars(props: Props, ref: Ref<SnackbarRef>) {
    const { message, onClose } = props;
    const [open, setOpen] = React.useState(false);

    const show = () => {
        setOpen(true);
    }

    const hide = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }))

    return (
        <div className={""}>
            <Snackbar open={open}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                      }}
                      autoHideDuration={4500}
                      onClose={hide}
                      message={message}
                      action={<>
                          <IconButton size="small" aria-label="close" color="inherit" onClick={hide}>
                              <CloseIcon fontSize="small"/>
                          </IconButton>
                      </>}/>
        </div>
    );
}

export default React.forwardRef(Snackbars);