const routes = {
    home: "/",
    schedule: "/schedule",
    rsvp: "/rsvp",
    accom: "/accommodation",
    registry: "/registry",
    brunch: "/brunch",
    photos: "/photos",
    saveTheDate: "/save-the-date",
    admin: "/admin",
    gallery: "/gallery",
    baby: "/baby"
}

export default routes;