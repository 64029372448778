import React, { useCallback, useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import MenuIcon from '@material-ui/icons/Menu';
import routes from "../../../constants/routes";
import styles from "./toolbar.module.scss";
import wattle from "../../../resources/images/flowers/wattle.png";
import redGumBlossoms from "../../../resources/images/flowers/red-gumblossoms.png";
import gumnuts from "../../../resources/images/flowers/gumnuts.png";
import goldenWattle from "../../../resources/images/flowers/golden-wattle.png";
import waratah from "../../../resources/images/flowers/waratah.png";
import oakLeaves from "../../../resources/images/flowers/oak-leaves.png";
import eucalyptus from "../../../resources/images/flowers/eucalyptus.png";

export default function Toolbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 920);
    const history = useHistory();

    const onWindowResize = useCallback(() => {
        if (!isMobile && window.innerWidth < 920) {
            setIsMobile(true);
        } else if (isMobile && window.innerWidth >= 920) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [onWindowResize]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }

    const onNavigate = (path: string) => () => {
        history.push(path);
        setDrawerOpen(false);
    }

    const menu = [
        { icon: <img alt={"icon"} src={wattle} className={styles.drawer_icon}/>, text: "Home", path: routes.home },
        { icon: <img alt={"icon"} src={redGumBlossoms} className={styles.drawer_icon}/>, text: "Schedule", path: routes.schedule },
        { icon: <img alt={"icon"} src={gumnuts} className={styles.drawer_icon}/>, text: "Accommodation", path: routes.accom },
        { icon: <img alt={"icon"} src={goldenWattle} className={styles.drawer_icon}/>, text: "RSVP", path: routes.rsvp },
        { icon: <img alt={"icon"} src={waratah} className={styles.drawer_icon}/>, text: "Registry", path: routes.registry },
        { icon: <img alt={"icon"} src={oakLeaves} className={styles.drawer_icon}/>, text: "Brunch", path: routes.brunch },
        { icon: <img alt={"icon"} src={eucalyptus} className={styles.drawer_icon}/>, text: "Photos", path: routes.photos },
    ]

    const renderDrawer = () => (
        <>
            <Drawer classes={{
                paper: styles.drawer
            }} open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                    {menu.map(({ icon, text, path }, index) => (
                        <ListItem button key={text} onClick={onNavigate(path)}>
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={text}/>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <MenuIcon onClick={toggleDrawer(true)} className={styles.hamburger}/>
        </>
    )

    const renderMenu = () => {
        return (
            <div className={styles.toolbar_inner}>
                <div className={styles.links}>
                    <span className={classNames(styles.menu_item)} onClick={onNavigate(routes.schedule)}>Schedule</span>
                    <span className={classNames(styles.menu_item)}
                          onClick={onNavigate(routes.accom)}>Accommodation</span>
                    <span className={classNames(styles.menu_item)} onClick={onNavigate(routes.rsvp)}>RSVP</span>
                    <h1 className={styles.home} onClick={onNavigate(routes.home)}>Catherine & James</h1>
                    <span className={classNames(styles.menu_item)} onClick={onNavigate(routes.registry)}>Registry</span>
                    <span className={classNames(styles.menu_item)} onClick={onNavigate(routes.brunch)}>Brunch</span>
                    <span className={classNames(styles.menu_item)}
                          onClick={onNavigate(routes.photos)}>Photos</span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.toolbar}>
            {isMobile
                ? renderDrawer()
                : renderMenu()}
        </div>
    )
}