import firebase, { User } from "firebase/app";
import "firebase/auth";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./Admin.module.scss";
import MaterialTable from "material-table";

export default function Admin() {
    const [user, setUser] = useState<User | null>(null);
    const [rsvps, setRsvps] = useState<any>(null);
    const db = firebase.firestore();


    const isAdminUser = (id: string | undefined) => {
        return (id === "LmvuiZnIwValNcGC6WaKm8eCB532" || id === "Z39GxzjoUKfKHlrUoLg6vUpc57N2");
    }

    firebase.auth().onAuthStateChanged((u) => {
        if (u) {
            setUser(u);
        }
    })

    useEffect(() => {
        if (isAdminUser(user?.uid)) {
            // fetch data
            db.collection("rsvp").get()
                .then(async (querySnapshot) => {
                    const docs: any = [];
                    await querySnapshot.forEach((doc) => {
                        docs.push(doc.data());
                    })
                    setRsvps(docs);
                })
        }
    }, [user])


    const onSubmit = async (values: any) => {
        try {
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        } catch (e) {
            console.log('err', e);
        }
    }

    const onSignOut = async () => {
        try {
            await firebase.auth().signOut();
            setUser(null);
        } catch (e) {
            console.log('err', e);
        }
    }

    if (isAdminUser(user?.uid)) {
        return (
            <div className={styles.container}>
                <div className={styles.title}>
                    <Button onClick={onSignOut}>Sign out</Button>
                </div>
                <div className={styles.table}>
                    <MaterialTable
                        columns={[
                            { title: "Name", field: "name" },
                            { title: "Email", field: "email" },
                            { title: "attending", field: "attending" },
                            { title: "Brunch", field: "brunch" },
                            { title: "Allergies", field: "allergies" },
                            { title: "Return Bus", field: "busHome" },
                            { title: "Music", field: "songs" },
                        ]}
                        data={rsvps || []}
                        options={{
                            emptyRowsWhenPaging: true,
                            pageSize: 20,
                        }}
                        title="RSVP"
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
                {({ values, handleChange, isSubmitting }) => {
                    return (
                        <Form>
                            <TextField
                                fullWidth
                                id={"email"}
                                name={"email"}
                                label={"Email"}
                                value={values.email}
                                onChange={handleChange}/>
                            <TextField
                                fullWidth
                                id={"password"}
                                name={"password"}
                                label={"Password"}
                                value={values.password}
                                type={"password"}
                                onChange={handleChange}/>
                            <Button type={"submit"}>{isSubmitting ? <CircularProgress size={25}/> : "Submit"}</Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}